import(/* webpackMode: "eager" */ "/codebuild/output/src1836487856/src/liv-place-frontend/apps/web/src/app/[locale]/clientLayout.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1836487856/src/liv-place-frontend/apps/web/src/app/[locale]/layout/LivAnalyticsProvider/LivAnalyticsProvider.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1836487856/src/liv-place-frontend/apps/web/src/contextProviders/LivUserContextProvider/LivUserProviderContextProviderClient.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1836487856/src/liv-place-frontend/apps/web/src/contextProviders/MuiThemeContextProvider/MuiThemeContextProviderClient.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1836487856/src/liv-place-frontend/apps/web/src/UI/sharedComponents/LivQueryProvider/LivQueryProvider.tsx");
import(/* webpackMode: "eager" */ "/codebuild/output/src1836487856/src/liv-place-frontend/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src1836487856/src/liv-place-frontend/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src1836487856/src/liv-place-frontend/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src1836487856/src/liv-place-frontend/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src1836487856/src/liv-place-frontend/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/codebuild/output/src1836487856/src/liv-place-frontend/node_modules/photoswipe/dist/photoswipe.css")