/* eslint-disable @typescript-eslint/no-unsafe-call -- Following library documentation */

'use client'

import React, { useEffect } from 'react'
import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'
import WebVitals from '@UI/sharedComponents/WebVitals'
import AnalyticsLib from 'analytics'
import { AnalyticsProvider } from 'use-analytics'

import { Router } from 'next/router'

import facebookPixelPlugin from 'lib/analytics/facebook-pixel-plugin'
import intercomPlugin from 'lib/analytics/intercom-plugin'
import postHog from 'lib/analytics/posthog-analytics-plugin'

import LivAwsRumProvider from './_components/LivAwsRumProvider'

const analytics = AnalyticsLib({
  app: 'LivPlace',
  debug: process.env.NODE_ENV === 'development',
  plugins: [
    googleAnalytics({
      measurementIds: [
        process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID ?? 'G-J2C1WW1SKQ',
      ],
    }),
    googleTagManager({
      containerId: process.env.NEXT_PUBLIC_GTM_ID ?? 'GTM-WXKB9D2',
    }),
    facebookPixelPlugin({
      pixelId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID ?? '935791191439007',
    }),
    intercomPlugin({
      appId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? 'zbhz2gs6',
    }),
    postHog({
      token: process.env.NEXT_PUBLIC_POSTHOG_KEY ?? '',
      enabled: true,
      options: {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST ?? '',
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true, // Highly recommended as a minimum!!
          },
        },
      },
    }),
  ],
})

export default function LivAnalyticsProvider({
  children,
}: Readonly<{
  children: React.ReactNode
}>): JSX.Element {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      void analytics.page()
    }

    const handleRouteChange = (): void => {
      void analytics.page()
    }

    Router.events.on('routeChangeComplete', handleRouteChange)

    // Cleanup function to remove the event listener
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  if (typeof window === 'undefined') {
    return (
      <>
        <WebVitals />
        <AnalyticsProvider instance={analytics}>{children}</AnalyticsProvider>
      </>
    )
  }

  return (
    <>
      <WebVitals />
      <LivAwsRumProvider>
        <AnalyticsProvider instance={analytics}>{children}</AnalyticsProvider>
      </LivAwsRumProvider>
    </>
  )
}
